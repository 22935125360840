*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root{
    height: 100vh;
    width: 100%;

}


.header{
    grid-area: header;
}

.main{
    grid-area: main;
    display: flex;
    /* overflow: auto; */
}

.footer{
    grid-area: footer;
    min-height: 40px;
    padding: 1rem 0;
}
